<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="75%"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>教室空闲时间</span>
    </div>
    <!-- tableData: {{tableData}}
    weekDayArr：{{weekDayArr}}
    chooseDate：{{chooseDate}} -->
    <el-row>
      <!-- <el-select v-model="campusId" placeholder="请选择校区" class="tb-smselect">
        <el-option v-for="item in typeArray" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select> -->
      <el-select v-model="campusId" placeholder="请选择校区" class="tb-smselect">
        <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select
        v-model="roomIds"
        multiple
        placeholder="请选择教室"
        :disabled="!Boolean(campusId)"
        @click.native="seleRoom"
        class="tb-smselect"
      >
        <el-option
          v-for="item in roomArray"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        class="tb-picker"
        v-model="chooseDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择上课日期">
      </el-date-picker>
      <el-button type="primary" size="small" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="small" @click="reset" class="tb-button">重置</el-button>
      <el-button type="primary" size="small" @click="lastclick" class="tb-button" :disabled="!currentFirstDate">上周</el-button>
      <el-button type="primary" size="small" @click="nextclick" class="tb-button" :disabled="!currentFirstDate">下周</el-button>
      <!-- table -->
      <el-table
        :data="tableData"
        :span-method="objectSpanMethod"
        border
        :row-class-name="tableRowClassName"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="classroomTitle" label="教室" align="center"></el-table-column>
        <el-table-column prop="timeSlice" label="时间段" align="center" width="80"></el-table-column>
        <el-table-column v-for="(item, index) in weekDayArr" :key="index" :label="item.adate" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.quesData.length !== 0">
              <p v-for="(ele, idx) of scope.row.quesData" :key="idx">
                <span v-if="ele.startTime.substring(0, 10) === item.adate.substring(0, 10)">
                  <el-popover placement="right-start" width="200" trigger="hover">
                    <ul>
                      <li>班级：{{ele.className}}</li>
                      <!-- <li>老师：{{ele.}}</li> -->
                      <li>校区：{{ele.crCampusName}}</li>
                    </ul>
                    <el-button slot="reference" type="text" style="color: #333">{{ele.startTime | formatDateEnd}}~{{ele.endTime | formatDateEnd}} </el-button>
                  </el-popover>
                </span>
              </p>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <sele-room
      :show="roomDialog"
      :campusId="campusId"
      @close="roomDialog=false"
      @seleRoomData="seleRoomData"
      :isMultiple="true"
      :lessRoom="lessRoom"
    ></sele-room>
  </el-dialog>
</template>

<script>
import { getListLessonByClassroom } from '@/api/senate/room'
import { getMyCampus } from '@/api/senate/class'
import { formatTate } from '@/utils/formatDate'
import seleRoom from '@/components/senate/sele-room'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    }
  },
  components: { seleRoom },
  data () {
    return {
      roomIds: [],
      roomArray: [],
      classroomIds: [],
      chooseDate: new Date(),
      roomDialog: false,
      campusId: '',
      teacherArray: [],
      tableData: [],
      schoolArray: [],
      lessRoom: [], // 选择的教室
      typeArray: [
        {
          value: null,
          label: '全部'
        },
        {
          value: '主教',
          label: '主教'
        },
        {
          value: '助教',
          label: '助教'
        }
      ],
      currentFirstDate: '',
      clen: 7,
      // todayDate: '',
      weekDayArr: [],
      minTime: '',
      maxTime: ''
    }
  },
  methods: {
    openDialog () {
      this.campusId = ''
      this.tableData = []
      this.roomIds = []
      this.weekDayArr = []
      this.chooseDate = new Date()
      this.getMyCampus()
      // this.todayDate = this.formatDate(new Date())
    },

    // 获取校区
    async getMyCampus () {
      const res = await getMyCampus({})
      this.schoolArray = res.body
    },

    close () {
      this.$emit('close')
    },

    search () {
      if (!this.chooseDate) {
        window.$msg('请选择排课时间段', 2)
        return false
      } else {
        this.weekDayArr = []
        // let now = new Date()
        let now = new Date(this.chooseDate)
        let nowTime = now.getTime()
        let day = now.getDay()
        let oneDayLong = 24 * 60 * 60 * 1000
        let MondayTime = nowTime - (day - 1) * oneDayLong
        let SundayTime = nowTime + (7 - day) * oneDayLong
        this.minTime = formatTate(new Date(MondayTime)).substring(0, 10) + ' 00:00:00'
        this.maxTime = formatTate(new Date(SundayTime)).substring(0, 10) + ' 23:59:00'
        this.setDate(new Date(this.chooseDate))
        this.getListLessonByClassroom()
      }
    },

    seleRoomData (data) {
      // console.log('seleRoomData', data)
      this.roomIds = [] // 置空选项
      this.roomArray = data
      data.forEach((ele, idx) => {
        this.roomIds.push(ele.id)
      })
    },

    reset () {
      this.roomIds = []
      this.campusId = ''
      this.chooseDate = null
      this.currentFirstDate = ''
    },

    // 选择教室
    seleRoom () {
      if (!this.campusId) {
        return false
      }
      this.roomArray.forEach((ele, idx) => {
        this.roomIds.forEach((item, index) => {
          // if (ele.userId === item) {
          //   this.seleAssisData.push(ele)
          // }
        })
      })
      this.roomDialog = true
    },

    async getListLessonByClassroom () {
      const res = await getListLessonByClassroom({
        pageNum: 1,
        pageSize: 1000,
        minTime: this.minTime,
        maxTime: this.maxTime,
        classroomId: this.roomIds.length !== 0 ? this.roomIds : null
      })
      this.tableData = res.body.list
      this.regroupData(this.tableData)
    },

    getNewList (arr, classroomId) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.classroomId) && res.set(a.classroomId, 1))
    },

    // 数据重组
    regroupData (data) {
      let qwData = []
      let classroomIds = []
      data.forEach((ele, idx) => {
        classroomIds.push({
          classroomId: ele.classroomId,
          classroomTitle: ele.classroomTitle
        })
      })
      classroomIds = this.getNewList(classroomIds) // 本次查询的教室
      classroomIds.forEach((ele, idx) => {
        qwData.push({
          timeSlice: '上午',
          quesData: [],
          classroomId: ele.classroomId,
          classroomTitle: ele.classroomTitle
        })
        qwData.push({
          timeSlice: '下午',
          quesData: [],
          classroomId: ele.classroomId,
          classroomTitle: ele.classroomTitle
        })
        qwData.push({
          timeSlice: '晚上',
          quesData: [],
          classroomId: ele.classroomId,
          classroomTitle: ele.classroomTitle
        })
      })

      // console.log('表格挂载数据', qwData)
      data.forEach((ele, idx) => {
        qwData.forEach((item, index) => {
          if (item.classroomId === ele.classroomId && 0 <= Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 12 && item.timeSlice === '上午') {
            item.quesData.push({ ...ele })
          } else if (item.classroomId === ele.classroomId && 12 < Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 18 && item.timeSlice === '下午') {
            item.quesData.push({ ...ele })
          } else if (item.classroomId === ele.classroomId && Number(ele.startTime.substring(10, 13)) > 18 && item.timeSlice === '晚上') {
            item.quesData.push({ ...ele })
          }
        })
      })
      // console.log('qwData', qwData)
      // qwData.forEach((item, index) => {
      //   data.forEach((ele, idx) => {
      //     if (0 < Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 12 && item.timeSlice === '上午') {
      //       item.quesData.push({ ...ele })
      //     } else if (12 < Number(ele.startTime.substring(10, 13)) && Number(ele.startTime.substring(10, 13)) <= 18 && item.timeSlice === '下午') {
      //       item.quesData.push({ ...ele })
      //     } else if (Number(ele.startTime.substring(10, 13)) > 18 && item.timeSlice === '晚上') {
      //       item.quesData.push({ ...ele })
      //     }
      //   })
      // })
      this.tableData = qwData
    },

    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    tableRowClassName ({ row, rowIndex, column }) {
      if (rowIndex % 3 === 0) {
        return 'info-row'
      } else if (rowIndex % 3 === 1) {
        return 'warning-row'
      } else if (rowIndex % 3 === 2) {
        return 'success-row'
      } else {
        return 'info-row'
      }
    },
    // 日期格式处理
    formatDate (date) {
      var year = date.getFullYear() + '-'
      var month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) + '-' : '0' + (date.getMonth() + 1) + '-'
      var day = (date.getDate() > 9) ? date.getDate() + '' : '0' + date.getDate() + ''
      var week =
        '(' +
        ['周天', '周一', '周二', '周三', '周四', '周五', '周六'] [
          date.getDay()
        ] +
        ')'
      return year + month + day + ' ' + week
    },
    //
    addDate (date, n) {
      date.setDate(date.getDate() + n)
      return date
    },
    //
    setDate (date) {
      var week = date.getDay() - 1
      date = this.addDate(date, week * -1)
      this.currentFirstDate = new Date(date)
      for (var i = 0; i < this.clen; i++) {
        this.weekDayArr.push({
          adate: this.formatDate(i === 0 ? date : this.addDate(date, 1))
        })
        // 只有日期
        // this.weekDayArr.push(this.formatDate(i==0 ? date : this.addDate(date,1)))
      }
    },
    // 上一周
    lastclick () {
      this.weekDayArr = []
      this.tableData = []
      let nextWeek = this.addDate(new Date(this.currentFirstDate), -7)
      let nowTime = nextWeek.getTime()
      let day = nextWeek.getDay()
      let oneDayLong = 24 * 60 * 60 * 1000
      let MondayTime = nowTime - (day - 1) * oneDayLong
      let SundayTime = nowTime + (7 - day) * oneDayLong
      this.minTime = formatTate(new Date(MondayTime)).substring(0, 10) + ' 00:00:00'
      this.maxTime = formatTate(new Date(SundayTime)).substring(0, 10) + ' 23:59:00'
      this.setDate(this.addDate(new Date(this.currentFirstDate), -7))
      this.getListLessonByClassroom()
    },
    // 下一周
    nextclick () {
      this.weekDayArr = []
      this.tableData = []
      let nextWeek = this.addDate(new Date(this.currentFirstDate), 7)
      let nowTime = nextWeek.getTime()
      let day = nextWeek.getDay()
      let oneDayLong = 24 * 60 * 60 * 1000
      let MondayTime = nowTime - (day - 1) * oneDayLong
      let SundayTime = nowTime + (7 - day) * oneDayLong
      this.minTime = formatTate(new Date(MondayTime)).substring(0, 10) + ' 00:00:00'
      this.maxTime = formatTate(new Date(SundayTime)).substring(0, 10) + ' 23:59:00'
      this.setDate(this.addDate(new Date(this.currentFirstDate), 7))
      this.getListLessonByClassroom()
    },

    getStateColor (state) {
      switch (state) {
        case '主教' :
          return '#4DA54D'
        case '助教' :
          return '#EB9316'
        default :
          return '#4DA54D'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/dialog.scss";

/deep/ .el-table .info-row {
  background: #E0E0F8;
}
/deep/ .el-table .warning-row {
  background: #B2C5FF;
}

/deep/ .el-table .success-row {
  background: #7787C5;
}
</style>
