import $axios from '@/utils/request'

// 创建教室
export function addClassroom (data) {
  const url = '/classroom/add'
  return $axios.fPost(url, data)
}

// 编辑教室
export function editClassroom (data) {
  const url = '/classroom/edit'
  return $axios.fPost(url, data)
}

// 删除教室
export function delClassroom (data) {
  const url = '/classroom/del'
  return $axios.fGet(url, data)
}

// 获取教室信息
export function getClassroomInfo (data) {
  const url = '/classroom/info'
  return $axios.fGet(url, data)
}

// 获取教室列表
export function getClassroomList (data) {
  const url = '/classroom/list'
  return $axios.fPost(url, data)
}

// 同步校管家教室
export function replaceByXgj (data) {
  const url = '/classroom/replaceByXgj'
  return $axios.fGet(url, data)
}

// 获取教室的排课列表
export function getListLessonByClassroom (data) {
  const url = '/lesson/listLessonByClassroom'
  return $axios.fPost(url, data)
}
